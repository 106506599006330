import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        pathMatch: 'full',
        path: '',
        loadChildren: () =>
            import('./modules/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'sao-paulo',
        loadChildren: () =>
            import('./modules/sao-paulo/saopaulo.module').then(
                (m) => m.SaopauloModule
            ),
    },
    {
        path: 'consulta-credenciado/:city',
        loadChildren: () =>
            import(
                './modules/consulta-credenciado/consulta-credenciado.module'
            ).then((m) => m.ConsultaCredenciadoModule),
    },
    {
        path: 'sao-caetano-do-sul',
        loadChildren: () =>
            import('./modules/sao-caetano/sao-caetano.module').then(
                (m) => m.SaoCaetanoModule
            ),
    },
    {
        path: 'faxinal-dos-guedes',
        loadChildren: () =>
            import(
                './modules/faxinal-dos-guedes/faxinal-dos-guedes.module'
            ).then((m) => m.FaxinalDosGuedesModule),
    },
    {
        path: 'gravatai',
        loadChildren: () =>
            import('./modules/gravatai/gravatai.module').then(
                (m) => m.GravataiModule
            ),
    },
    {
        path: 'campo-verde',
        loadChildren: () =>
            import(
                './modules/campo-verde/campo-verde.module'
            ).then((m) => m.CampoVerdeModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
